<template>
    <div class="A_map_box">
        <div class="amap-page-container">
            <el-amap
                ref="map"
                vid="amapDemo"
                :zoom="zoom"
                :center="center"
                class="amap-demo"
                :events="events"
            >
                <el-amap-marker
                    vid="component-marker"
                    :position="posPic"
                    :content="iconContent"
                ></el-amap-marker>
                <el-amap-marker
                    vid="component-pos"
                    :position="posPic"
                    :content="content"
                    :events="markEvent"
                ></el-amap-marker>
            </el-amap>
        </div>
    </div>
</template>
 <script>
 import posIcon from "@/assets/img/position-icon.png";
 import posTip from "@/assets/img/pos-tip.png";
export default {
    name: "amap-page",
    components: {},
    data() {
        var me = this;
        return {
            city: "北京",
            currIndex: 0,
            zoom: 16,
            center: [116.479578,39.891897],
            posPic:[116.479571,39.891903],
            icon:posIcon,
            content:'<img style="transform: translate(-66px,-78px);" src="'+posTip+'" width="156px" />',
            iconContent:'<img  src="'+posIcon+'" width="25px" />',
            markEvent:{
                click:()=>{
                     this.center =  [116.479578,39.891897];
                     this.zoom = 19;
                   
                     this.$nextTick(()=>{
                           this.zoom = 18;
                     })
                }
            },
            events: { 
                init: () => {
                    // o.setCity(me.city, result => {
                    //     if (result && result.length > 0) {
                    //         me.zoom = 16;
                    //         me.makerConf.position = result;
                    //     }
                    // });
                    this.center =  [116.479578,39.891897];
                     me.makerConf.position = [116.479578,39.891897];
                     this.zoom = 18;
                },

                click: e => {
                    let { lnglat } = e;
                     console.log(lnglat.lng, lnglat.lat)
                    me.makerConf.position = [lnglat.lng, lnglat.lat];
                }
            },
            makerConf: {
                position: [116.479578,39.891897],
                content: ""
            },
            searchOption: {
                city: this.city,
                citylimit: true
            },
            // plugin: [
            //     {
            //         pName: "ToolBar",

            //         events: {
            //             init(instance) {
            //                 console.log(instance);
            //             }
            //         }
            //     },
            //     "Scale"
            // ],

            allCityList: []
        };
    },
    created() {
        this.center =  [116.479578,39.891897];
    },

    mounted() {
       
    },
    methods: {
        toMap(){
            this.center =  [116.479578,39.891897];
            this.zoom = 18;
        }
    },
    watch: {
        
    }
};
</script>
 <style lang="scss" scoped>
.amap-page-container {
    height: 400px;
    position: relative;
}

// .search-box {
//     position: absolute !important;
//     top: 25px;
//     left: 100px;
//     z-index: 200 !important;
//     opacity: 0.9;
// }

// .select-box {
//     position: absolute !important;
//     top: 25px;
//     right: 20px;
//     z-index: 200 !important;
//     width: auto !important;
//     opacity: 0.9;
// }

.amap-toolbar {
    position: absolute;
    width: 52px;
    overflow: visible;
    top: 100px !important;
}

// .amap-demo {
//     height: 500px;
// }

// .amap-logo {
//     display: none;
// }

// .amap-copyright {
//     bottom: -100px;
//     display: none;
// }

// .amap-scalecontrol {
//     bottom: 4px !important;
// }

// .amap-geolocation-con {
//     bottom: 30px !important;
//     z-index: 199 !important;
// }

// ul li.active {
//     color: deeppink;
// }
</style>