    <!-- 联系我们-->

<template>
    <div class="Aboutme">
        <div class="banner-box">
            <div class="content">
                <div class="banner-text">
                    <img
                        src="@/assets/img/aboutme/contactus.png"
                        width="285px"
                    />
                    <p>联系我们</p>
                    <span>科技 开放 共赢 生生不息</span>
                </div>
            </div>
        </div>
        <div class="content po-r info-box">
            <img src="@/assets/img/aboutme/qrcode-aboutme.png" width="241px" />
            <p>优选好生活科技（北京）有限公司</p>
            <ul>
                <li>电话 : <b>010-67718382</b></li>
                <li>邮箱 : <b>13601268852@139.com</b></li>
                <li>地址 : <b>北京市朝阳区西大望路甲23号合生创展大楼</b></li>
            </ul>
            <div class="arrow-line"></div>
            <amap></amap>
            <div class="add-box">
                <img src="@/assets/img/aboutme/add-tip.png" height="126px" />
                <div class="add-text flex fl-ai-c">
                    <div v-for="(item,i) in addList" :key="i">
                        <div>{{item.title}}</div>
                        <p>电话：{{item.tel}}</p>
                        <p>
                            地址：<span
                                >{{item.add}}</span
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import amap from "@/components/amap";
export default {
  name: 'Aboutme',
  components: {
      amap
  },
  data(){
      return{
          addList:[
              {title:'广州分公司',tel:"020-88523756",add:"广州市天河区冼村路11号之二保利威座北塔写字楼"},
              {title:'上海分公司',tel:"15205171230",add:"上海市静安区灵石路695号14楼1405A室"},
              {title:'成都分公司',tel:"18980713061",add:"成都市温江路光华大道东三段珠江悦湖国际售楼部"},
              {title:'武汉分公司',tel:"13886299010",add:"武汉市洪山区徐东大街85号中冶南方大厦A801"},          
          ]
      }
  },     
}
</script>
<style lang="scss" scoped>
.banner-box {
    height: 374px;
    background: #f6faff;

    .content {
        height: 100%;
        background: url("~@/assets/img/aboutme/aboutme-banner.png") no-repeat
            top right #f6faff;
        background-size: contain;
    }
    .banner-text {
        padding-top: 80px;
        > p {
            font-size: 48px;
            font-weight: bold;
            line-height: 62px;
            letter-spacing: 2px;
            margin-bottom: 8px;
            margin-top: 4px;
        }
        > span {
            font-size: 28px;
            line-height: 42px;
            letter-spacing: 2px;
        }
    }
}
.info-box {
    padding-top: 80px;
    padding-bottom: 23px;
    > img {
        position: absolute;
        right: 0;
        top: 0;
    }
    > p {
        font-size: 22px;
        font-weight: bold;
        line-height: 29px;
        margin-bottom: 16px;
    }
    > ul > li {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 24px;
        > b {
            color: #4898ff;
        }
    }
}
.arrow-line {
    height: 5px;
    background: #4898ff;
    position: relative;
    margin-top: 23px;

    &::before {
        position: absolute;
        content: "";
        border: 13px solid #4898ff;
        border-color: transparent;
        border-bottom-color: #4898ff;
        border-width: 0 11px 18px 11px;
        height: 12px;
        top: -25px;
        left: 5px;
    }
}
.add-box {
    margin-top: 46px;
    > img {
        margin: 0 -19px;
        margin-top: -14px;
    }
}
.add-text {
    > div {
        flex: 1;
        
        &+div{
            margin-left: 30px;
        }
        > div {
            font-size: 22px;
            font-weight: bold;
            line-height: 29px;
            margin-bottom: 24px;
        }
        > p {
            font-size: 18px;
            line-height: 24px;
            margin-top: 12px;
            > span {
                display: inline-block;
                width: 220px;
                vertical-align: top;
            }
        }
    }
}
</style>
